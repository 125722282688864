import MainCard from "components/MainCard";
import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Button,
  MenuItem,
  OutlinedInput,
  Grid,
  Select,
  InputLabel,
  Drawer,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { Table, Modal, Upload, Button as Button1 } from "antd";
import { cost_submit, get_api_function } from "utils/api";
import { get_cost_details } from "utils/api";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { UploadOutlined } from "@ant-design/icons";

function TimeHistory({ time_history }) {
  return (
    <Table
    bordered
      dataSource={time_history}
      columns={[
        {
          title: "Start Time",
          dataIndex: "start_time",
          key: "start_time",
          width: "30%",
          render: (start_time) => start_time?.slice(0, 19),
        },
        {
          title: "End Time",
          dataIndex: "end_time",
          key: "end_time",
          width: "30%",
          render: (end_time) => end_time?.slice(0, 19),
        },
        {
          title: "By",
          dataIndex: "ation_employee_name",
          key: "by",
          width: "40%",
        },
      ]}
      pagination={false}
    />
  );
}

export default TimeHistory;
