import PropTypes from "prop-types";
import { forwardRef } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  CardActions,
  Button,
  useMediaQuery,
  Stack,
} from "@mui/material";

// project import
import Highlighter from "./third-party/Highlighter";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// header style
const headerSX = {
  p: 2.5,
  pb: 0,
  "& .MuiCardHeader-action": { m: "0px auto", alignSelf: "center" },
};

// ==============================|| CUSTOM - MAIN CARD ||============================== //

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentSX = {},
      darkTitle,
      elevation,
      secondary,
      shadow,
      footer,
      sx = {},
      title,
      codeHighlight,
      backbutton,
      customHeaderStyle,
      ...others
    },
    ref
  ) => {
    const theme = useTheme();
    boxShadow = theme.palette.mode === "dark" ? boxShadow || true : boxShadow;
    const navigate = useNavigate();
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));

    return (
      <Card
        elevation={elevation || 0}
        ref={ref}
        {...others}
        sx={{
          m: { sm: "0", lg: "10px !important" },
          border: border ? "1px solid" : "none",
          borderRadius: 1,
          borderColor:
            theme.palette.mode === "dark"
              ? theme.palette.divider
              : theme.palette.grey.A800,
          boxShadow:
            boxShadow && (!border || theme.palette.mode === "dark")
              ? shadow || theme.customShadows.z1
              : "inherit",
          ":hover": {
            boxShadow: boxShadow ? shadow || theme.customShadows.z1 : "inherit",
          },
          "& pre": {
            m: "0",
            p: { sm: "0", lg: "16px !important" },
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.75rem",
          },
          ...sx,
        }}
      >
        {/* card header and action */}
        {!darkTitle && title && !backbutton && !secondary && (
          <CardHeader
            sx={{ ...headerSX, ...customHeaderStyle }}
            titleTypographyProps={{ variant: "subtitle1" }}
            title={<Typography variant="h5">{title}</Typography>}
            action={secondary}
          />
        )}
        {darkTitle && title && !backbutton && (
          <CardHeader
            sx={{ ...headerSX, ...customHeaderStyle }}
            title={<Typography variant="h3">{title}</Typography>}
            action={secondary}
          />
        )}
        {!darkTitle && title && backbutton && (
          <CardHeader
            sx={{ ...headerSX, ...customHeaderStyle }}
            titleTypographyProps={{ variant: "subtitle1" }}
            title={
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mx: { xs: 0, lg: 1 } }}
              >
                <Typography variant="h5">{title}</Typography>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  startIcon={<ArrowBackIcon />}
                >
                  {isLargeScreen ? "Back" : ""}
                </Button>
              </Stack>
            }
            action={secondary}
          />
        )}

        {!darkTitle && !title && backbutton && (
          <CardHeader
            sx={{ ...headerSX, ...customHeaderStyle }}
            titleTypographyProps={{ variant: "subtitle1" }}
            title={
              <Stack direction="row" justifyContent="end">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  startIcon={<ArrowBackIcon />}
                >
                  {isLargeScreen ? "Back" : ""}
                </Button>
              </Stack>
            }
            action={secondary}
          />
        )}

        {darkTitle && title && backbutton && (
          <CardHeader
            sx={{ ...headerSX, ...customHeaderStyle }}
            title={
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h3">{title}</Typography>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  startIcon={<ArrowBackIcon />}
                >
                  {isLargeScreen ? "Back" : ""}
                </Button>
              </Stack>
            }
            action={secondary}
          />
        )}

        {title && secondary && (
          <CardHeader
            sx={{
              ...headerSX,
              ...customHeaderStyle,
            }}
            title={
              <Stack direction="row" justifyContent="start">
                <Typography variant="h5">{title}</Typography>
              </Stack>
            }
            action={secondary}
          />
        )}

        {/* card content */}
        {content && <CardContent sx={contentSX}>{children}</CardContent>}
        {!content && children}

        {title && footer && (
          <CardActions
            sx={{
              backgroundColor: "#f0f0f0",
              pb: 2,
            }}
          >
            {footer} {/* Render footer content */}
          </CardActions>
        )}

        {/* card footer - clipboard & highlighter  */}
        {codeHighlight && (
          <>
            <Divider sx={{ borderStyle: "dashed" }} />
            <Highlighter codeHighlight={codeHighlight} main>
              {children}
            </Highlighter>
          </>
        )}
      </Card>
    );
  }
);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  divider: PropTypes.bool,
  elevation: PropTypes.number,
  secondary: PropTypes.node,
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  codeHighlight: PropTypes.bool,
  content: PropTypes.bool,
  children: PropTypes.node,
};

export default MainCard;
