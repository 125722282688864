import MainCard from "components/MainCard";
import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Button,
  MenuItem,
  OutlinedInput,
  Grid,
  Select,
  InputLabel,
  Drawer,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { Table, Modal, Upload, Button as Button1 } from "antd";
import { cost_submit } from "utils/api";
import { get_cost_details } from "utils/api";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { UploadOutlined } from "@ant-design/icons";

function CostInvolved({ history_ticket_id }) {
  const [cost_details, set_cost_details] = useState([]);
  const [total_cost, set_total_cost] = useState([]);

  useEffect(() => {
    get_cost_details(success_get_cost, { ticket_id: history_ticket_id });
  }, []);

  const success_get_cost = (res) => {
    set_cost_details(res.data.data);
    set_total_cost(res.data.total_cost);
  };

  const [costOpen, setCostOpen] = useState(false);
  const handleCancel = () => {
    setCostOpen(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "employee_name",
      key: "name",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (dataIndex) => {
        return dataIndex === "undefined" ? "" : dataIndex;
      },
    },
  ];

  const cost_attachment_obj = {};
  const [cost_value, set_constValue] = useState("");
  const [reason, set_reason] = useState("");
  const [cost_attach, set_cost_attach] = useState([]);

  const handlecosts = (e) => {
    if (
      /^[-+]?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?$/.test(e.target.value) ||
      e.target.value === ""
    ) {
      cost_attachment_obj.cost = e.target.value;
      set_constValue(e.target.value);
    }
  };
  const handleReason = (e) => {
    cost_attachment_obj.reason = e.target.value;
    set_reason(e.target.value);
  };
  const handleCostFileList = ({ fileList }) => {
    set_cost_attach(fileList);
    if (fileList.length >= 1) {
      cost_attachment_obj.attachment = fileList[0]?.originFileObj;
    }
    if (fileList.length >= 2) {
      cost_attachment_obj.attachment1 = fileList[1]?.originFileObj;
    }
    if (fileList.length == 3) {
      cost_attachment_obj.attachment2 = fileList[2]?.originFileObj;
    }
  };

  const resetCost = () => {
    set_constValue("");
    set_reason("");
    set_cost_attach([]);
  };

  // ---------------------------ACTIONS --------------------------------------------------

  const handle_cost_submit = (e) => {
    e.preventDefault();
    const costPostData = new FormData();
    costPostData.append("cost", cost_value);
    costPostData.append("reason", reason);
    costPostData.append("attachment", cost_attach[0]?.originFileObj);
    costPostData.append("attachment1", cost_attach[1]?.originFileObj);
    costPostData.append("attachment2", cost_attach[2]?.originFileObj);
    costPostData.append("ticket_id", history_ticket_id);
    cost_submit(costPostData, success_cost_updated);
  };
  const success_cost_updated = (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      get_cost_details(success_get_cost, { ticket_id: history_ticket_id });
      resetCost();
      setCostOpen(false);
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item lg={4}>
          <Typography variant="h5">{`Total Cost :`}</Typography>
        </Grid>
        <Grid item lg={4}>
          <Typography>{`${total_cost}`}</Typography>
        </Grid>
        <Grid item lg={4} container direction="row" justifyContent="end">
          <Tooltip title="Add Cost">
            <Button
              variant="contained"
              size="small"
              startIcon={costOpen ? <CloseIcon /> : <AddIcon />}
              onClick={() => {
                setCostOpen(!costOpen);
              }}
            >
              {costOpen ? "Close " : "Cost"}
            </Button>
          </Tooltip>
        </Grid>
        <Grid item lg={12}>
          <Table bordered dataSource={cost_details} columns={columns} />
        </Grid>
      </Grid>

      <Modal
        title="Add Cost"
        open={costOpen}
        onCancel={handleCancel}
        footer={[]}
        zIndex={2148}
      >
        <form onSubmit={handle_cost_submit}>
          <Grid container spacing={2}>
            <Grid item lg={12} container>
              <Grid item lg={3} alignContent="center">
                <InputLabel htmlFor="ticketType" sx={{ fontSize: "16px" }}>
                  Cost:
                </InputLabel>
              </Grid>
              <Grid item lg={9} alignContent="center">
                <OutlinedInput
                  type="text"
                  size="small"
                  value={cost_value}
                  onChange={handlecosts}
                  required
                />
              </Grid>
            </Grid>
            <Grid item lg={12}>
              <Stack spacing={2}>
                <Grid
                  item
                  lg={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    lg={3}
                  >
                    <InputLabel htmlFor="ticketType" sx={{ fontSize: "16px" }}>
                      Reason:
                    </InputLabel>
                  </Grid>
                  <Grid
                    item
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    lg={9}
                  >
                    <OutlinedInput
                      type="text"
                      size="small"
                      value={reason}
                      onChange={handleReason}
                      required
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  lg={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    lg={3}
                  >
                    <InputLabel htmlFor="ticketType" sx={{ fontSize: "16px" }}>
                      Attachments:
                    </InputLabel>
                  </Grid>
                  <Grid
                    item
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    lg={9}
                  >
                    <Upload
                      fileList={cost_attach}
                      maxCount={3}
                      onChange={handleCostFileList}
                    >
                      <Button1 icon={<UploadOutlined />}>
                        Add Attachment
                      </Button1>
                    </Upload>
                  </Grid>
                </Grid>

                <Stack spacing={2} direction="row" justifyContent="end">
                  <Button variant="contained" type="submit">
                    Confirm
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleCancel();
                      resetCost();
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </React.Fragment>
  );
}

export default CostInvolved;
