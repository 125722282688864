import React from "react";
import {
  Upload,
  Button as Button1,
  Typography as Typography1,
  Modal,
  Table,
} from "antd";
import {
  Typography,
  Stack,
  Button,
  MenuItem,
  OutlinedInput,
  Grid,
  Select,
  InputLabel,
  Drawer,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { Timeline, Image, Descriptions, Popover } from "antd";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { InfoCircleTwoTone, InfoCircleFilled } from "@ant-design/icons";

function TimeLineData({ flow_data }) {
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Timeline
      mode="left"
      style={{
        height: "70vh",
        overflowY: "scroll",
        padding: 10,
      }}
      items={[
        ...flow_data?.map((flow, index) => {
          return {
            color:
              index === 0
                ? "green"
                : flow?.TimeDifferenceComparison
                ? "green"
                : "red",
            children: (
              <React.Fragment>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" spacing={1}>
                    <Typography1
                      level={5}
                      ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
                    >
                      {flow.Status !== "Resolved" &&
                        flow.Status !== "Issue Not Resolved" &&
                        (matchDownSM
                          ? null
                          : flow?.date?.slice(0, 19) ||
                            flow?.Date?.slice(0, 19))}
                    </Typography1>

                    <Popover
                      placement="bottomRight"
                      title="Details"
                      content={
                        <Descriptions
                          column={1}
                          style={{
                            width: 300,
                          }}
                          size="small"
                          items={[
                            ...(flow.Status !== "Resolved" &&
                            flow.Status !== "Issue Not Resolved"
                              ? [
                                  ...(flow.By
                                    ? [
                                        {
                                          key: "1",
                                          label: "Name",
                                          children: flow.By,
                                        },
                                      ]
                                    : []),
                                  ...(flow.Department
                                    ? [
                                        {
                                          key: "3",
                                          label: "Department",
                                          children: flow.Department,
                                        },
                                      ]
                                    : []),
                                  ...(flow.Designation
                                    ? [
                                        {
                                          key: "4",
                                          label: "Designation",
                                          children: flow.Designation,
                                        },
                                      ]
                                    : []),
                                ]
                              : []),
                          ]}
                        />
                      }
                    >
                      <InfoCircleTwoTone twoToneColor="#FAAD14" />
                    </Popover>
                  </Stack>
                  {flow.TimeDifference &&
                    flow.TimeDifferenceComparison !== null && (
                      <Typography
                        sx={{
                          color: flow.TimeDifferenceComparison
                            ? "#52C41A"
                            : "#FF4D4F",
                        }}
                      >
                        Time Taken: {flow.TimeDifference}
                      </Typography>
                    )}
                </Stack>
                <Typography1.Title
                  level={5}
                  ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
                  style={{ margin: "10px 0" }}
                >
                  {flow.Status}
                </Typography1.Title>
                <Descriptions
                  column={1}
                  size="small"
                  items={[
                    ...(flow.Status !== "Resolved" &&
                    flow.Status !== "Issue Not Resolved"
                      ? [
                          ...(flow.comment
                            ? [
                                {
                                  key: "5",
                                  label: "Comment",
                                  children: flow.comment,
                                },
                              ]
                            : []),
                          // ...(flow.TimeDifference &&
                          // flow.TimeDifferenceComparison !== null
                          //   ? [
                          //       {
                          //         key: "6",
                          //         label: "Time Taken",
                          //         children: (
                          //           <Typography
                          //             sx={{
                          //               color: flow.TimeDifferenceComparison
                          //                 ? "#52C41A"
                          //                 : "#FF4D4F",
                          //             }}
                          //           >
                          //             {flow.TimeDifference}
                          //           </Typography>
                          //         ),
                          //       },
                          //     ]
                          //   : []),
                          ...(flow.attachment
                            ? [
                                {
                                  key: "7",
                                  label: "Attachments",
                                  children: (
                                    <Stack direction="row" spacing={2}>
                                      {flow.attachment && (
                                        <Image
                                          width={100}
                                          src={`data:image/png;base64,${flow.attachment}`}
                                        />
                                      )}
                                      {flow.attachment1 && (
                                        <Image
                                          width={100}
                                          src={`data:image/png;base64,${flow.attachment1}`}
                                        />
                                      )}
                                      {flow.attachment2 && (
                                        <Image
                                          width={100}
                                          src={`data:image/png;base64,${flow.attachment2}`}
                                        />
                                      )}
                                    </Stack>
                                  ),
                                },
                              ]
                            : []),
                        ]
                      : []),
                  ]}
                />
              </React.Fragment>
            ),
          };
        }),
      ]}
    />
  );
}

export default TimeLineData;
