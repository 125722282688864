import Axios from "axios";

export const login_api = async (username, password, success) => {
  const response = await fetch(`/api/token/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
    // console.log("success")
  } else if (response.status === 500) {
    console.log("Internal Server Error");
  } else {
    console.log("failed", text, response);
    Object.entries(JSON.parse(text))?.forEach(([key, value]) => {
      console.log("fail");
      success("login failed");
    });
  }
};

export const forgot_password_API = async (email, success) => {
  const response = await fetch(`/pm/forgot_password`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
    }),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else if (response.status === 500) {
    console.log("Internal Server Error");
  } else {
    console.log("failed", text, response);
  }
};

export const set_password_API = async (set_password_data, success) => {
  const response = await fetch(`/pm/set_password`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(set_password_data),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else if (response.status === 500) {
    console.log("Internal Server Error");
  } else {
    console.log("failed", text, response);
  }
};

export const get_api_function = async (url, success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get(url, {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const post_api_function = async (url, success, data) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const post_formadata_api_function = async (url, success, data) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const get_user_details = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/userprofile", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const get_user_role = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_user_role", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

// MACHINE
export const get_all_machineDetails = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/pm/machine_details", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_machine_list_details = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/pm/get_machine_list_details", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_machine_list_details_pagination = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/pm/get_machine_list_details_pagination", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_spare_part_inventory = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/pm/get_spare_part_inventory", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_spare_details_by_machine = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/pm/get_spare_details_by_machine", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_spare_part_name = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_spare_part_name", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_spare_part_name_pagination = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_spare_part_name_pagination", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_spare_inventory_details = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/pm/get_spare_inventory_details", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_kit_inventory_details = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_kit_inventory_details", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_low_running_spare = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/pm/get_low_running_spare", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_spare_category = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/pm/get_spare_category", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_manufacturer_list = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/pm/get_part_manufacturer", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_manufacturer = async (success, manufacturer_name) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_part_manufacturer`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(manufacturer_name),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const maintenace_ticket_use = async (success, body) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/maintenace_ticket_use`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_spare_part_name = async (success, spare) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/add_spare_part_name`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(spare),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_spare_category = async (success, spare) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_spare_category`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(spare),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const save_spare_part_inventory = async (success, spare_part) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/save_spare_part_inventory`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(spare_part),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_assigned_checklist = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_assigned_checklist", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_ticket_details_excel = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_ticket_details_excel", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_spare_detail_by_ticket = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_spare_detail_by_ticket", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_technician = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_all_employee", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_action_name = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_action_name", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_action_name = async (success, action_name) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_action_name`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(action_name),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_machine_action_details = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_machine_action_details", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_machine_action_details = async (
  success,
  machine_action_details
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_machine_action_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(machine_action_details),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
//-------------------------------Break Down-----------------------------------------

export const get_breakdown_type = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_breakdown_type", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_breakdown_type = async (success, breakdown_type) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_breakdown_type`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(breakdown_type),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_breakdown_nature = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_breakdown_nature", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_breakdown_nature = async (success, breakdown_nature) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_breakdown_nature`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(breakdown_nature),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_machine_breakdown_details = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_machine_breakdown_details", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const save_machine_breakdown = async (success, machine) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/save_machine_breakdown`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(machine),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_machine_history = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_machine_history", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_machine_breakdown_details = async (
  success,
  breakdown_data
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_machine_breakdown_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(breakdown_data),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const machine_calender = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/machine_calender", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

// --------------------------------CHECKLIST--------------------------------------------

export const add_machine_type = async (success, machine_type) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/machine_category`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(machine_type),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_attribute_section = async (success, section) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_attribute_section`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(section),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_unit = async (success, unit) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_unit`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(unit),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const line_location_config_view = async (success, line) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/line_location_config_view`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(line),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const update_maintenace_ticket_status = async (success, ticket) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/update_maintenace_ticket_status`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(ticket),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_machine_checklist = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_machine_checklist", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_attribute_details_for_machine = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_attribute_details_for_machine", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_tech_ckecklist_value = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_tech_ckecklist_value", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_checklist_attribute_status = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_checklist_attribute_status", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const machine_category = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/machine_category", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_machine_status = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_machine_status", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_problem_solution = async (success, problem) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_problem_solution`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(problem),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_problem_solution = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("pm/get_problem_solution", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_problem = async (success, problem) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_machine_problem`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(problem),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_machine_problem_list = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("pm/get_machine_problem_list", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_solution = async (success, solution) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_machine_solution`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(solution),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_machine_solution_list = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("pm/get_machine_solution_list", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const assign_technician = async (success, assign_data) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/assigned_checklist`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: assign_data,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const save_checklist_value = async (success, checklist_value_data) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/save_checklist_value`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(checklist_value_data),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

// -----------------------------------------------------------------------------
export const add_Equipment = async (equipmentFormData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/machine_details`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: equipmentFormData,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const machine_update = async (equipmentFormData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/machine_update`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: equipmentFormData,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const get_machine_attributes = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_all_attribute", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_designation_edges = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_designation_hierarchy", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_designation_nodes = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_desig_hirarchy_values", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const set_designation_edges = async (success, designation_edges) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_designation_hierarchy`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(designation_edges),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const set_designation_nodes = async (success, designation_nodes) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_desig_hirarchy_values`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      data_flow: designation_nodes,
    }),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const add_Machine_Attribute_Name = async (parameterNameObj, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/mchine_attribute`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(parameterNameObj),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};
export const add_spare_attribute = async (parameterNameObj, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_spare_attribute`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(parameterNameObj),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const add_Machine_Attribute = async (machine_Attribute, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/machine_attribute_value`, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: machine_Attribute,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const get_machine_time_record = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_machine_time_record", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const save_machine_time_record = async (success, time) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/save_machine_time_record`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(time),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const update_machine_attribute_value = async (
  machine_Attribute,
  success
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/update_machine_attribute_value`, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: machine_Attribute,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

// TICKET
export const get_all_ticketDetails = async (success, param) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/create_ticket", {
    params: param,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_saved_ticketDetails = async (
  success,
  search_item,
  page_number,
  fromDate,
  toDate,
  issue_type_filter_value,
  location_filter_value,
  asset_filter_value
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_favourite_tickets", {
    params: {
      ...(search_item ? { search_item: search_item } : {}),
      page_number: page_number,
      ...(fromDate ? { start_date: fromDate } : {}),
      ...(toDate ? { end_date: toDate } : {}),
      ...(issue_type_filter_value?.length > 0 && {
        issue_type_id: issue_type_filter_value?.join(","),
      }),
      ...(location_filter_value?.length > 0 && {
        location_id: location_filter_value?.join(","),
      }),
      ...(asset_filter_value?.length > 0 && {
        machine_id: asset_filter_value?.join(","),
      }),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_pending_tickets = async (
  success,
  search_item,
  page_number,
  fromDate,
  toDate,
  issue_type_filter_value,
  location_filter_value,
  asset_filter_value
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_pending_tickets", {
    params: {
      search_item: search_item,
      page_number: page_number,
      ...(fromDate ? { start_date: fromDate } : {}),
      ...(toDate ? { end_date: toDate } : {}),
      ...(issue_type_filter_value?.length > 0 && {
        issue_type_id: issue_type_filter_value?.join(","),
      }),
      ...(location_filter_value?.length > 0 && {
        location_id: location_filter_value?.join(","),
      }),
      ...(asset_filter_value?.length > 0 && {
        machine_id: asset_filter_value?.join(","),
      }),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_machine_tickets = async (
  success,
  search_item,
  page_number,
  fromDate,
  toDate,
  issue_type_filter_value,
  location_filter_value,
  asset_filter_value
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/filter_ticket_user_created", {
    params: {
      page_number: page_number,
      ...(fromDate ? { start_date: fromDate } : {}),
      ...(search_item ? { search_item: search_item } : {}),
      ...(toDate ? { end_date: toDate } : {}),
      ...(issue_type_filter_value?.length > 0 && {
        issue_type_id: issue_type_filter_value?.join(","),
      }),
      ...(location_filter_value?.length > 0 && {
        location_id: location_filter_value?.join(","),
      }),
      ...(asset_filter_value?.length > 0 && {
        machine_id: asset_filter_value?.join(","),
      }),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_save_ticket = async (save_ticket_details, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_favourite_ticket`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(save_ticket_details),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_user_all_ticketDetails = async (
  success,
  search_item,
  page_number,
  fromDate,
  toDate,
  issue_type_filter_value,
  location_filter_value,
  asset_filter_value
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_all_ticket", {
    params: {
      search_item: search_item,
      page_number: page_number,
      ...(fromDate ? { start_date: fromDate } : {}),
      ...(toDate ? { end_date: toDate } : {}),

      ...(issue_type_filter_value?.length > 0 && {
        issue_type_id: issue_type_filter_value?.join(","),
      }),
      ...(location_filter_value?.length > 0 && {
        location_id: location_filter_value?.join(","),
      }),
      ...(asset_filter_value?.length > 0 && {
        machine_id: asset_filter_value?.join(","),
      }),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_user_department_tickets = async (
  success,
  search_item,
  page_number,
  fromDate,
  toDate,
  issue_type_filter_value,
  location_filter_value,
  asset_filter_value
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_department_tickets", {
    params: {
      search_item: search_item,
      page_number: page_number,
      ...(fromDate ? { start_date: fromDate } : {}),
      ...(toDate ? { end_date: toDate } : {}),
      ...(issue_type_filter_value?.length > 0 && {
        issue_type_id: issue_type_filter_value?.join(","),
      }),
      ...(location_filter_value?.length > 0 && {
        location_id: location_filter_value?.join(","),
      }),
      ...(asset_filter_value?.length > 0 && {
        machine_id: asset_filter_value?.join(","),
      }),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_ticket_approval = async (
  success,
  search_item,
  page_number
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/ticket_approval", {
    params: { search_item: search_item, page_number: page_number },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_created_by_me = async (
  success,
  search_item,
  page_number,
  fromDate,
  toDate,
  issue_type_filter_value,
  location_filter_value,
  asset_filter_value
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/ticket_user_created", {
    params: {
      search_item: search_item,
      page_number: page_number,
      ...(fromDate ? { start_date: fromDate } : {}),
      ...(toDate ? { end_date: toDate } : {}),
      ...(issue_type_filter_value?.length > 0 && {
        issue_type_id: issue_type_filter_value?.join(","),
      }),
      ...(location_filter_value?.length > 0 && {
        location_id: location_filter_value?.join(","),
      }),
      ...(asset_filter_value?.length > 0 && {
        machine_id: asset_filter_value?.join(","),
      }),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_flow_details = async (success, ticket_id) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/ticket_history", {
    params: { ticket_id: ticket_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const main_dashboard_analysis = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/dashboard_analysis", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const spare_analysis = async (params, success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/pm/spare_analysis", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const all_spare_analysis = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/all_spare_analysis", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const spare_history = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/pm/spare_history", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_spare_part_history = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_spare_part_history", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_spare_part_history_outward = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_spare_part_history_outward", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_spare_kit_history_outward = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_spare_kit_history_outward", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const machine_analysis = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/machine_analysis", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const count_analysis_ticket_user = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/analysis_ticket_user", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const count_analysis_ticket_manager = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/analysis_for_manager", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const count_analysis_for_department = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/analysis_for_department", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_issue_types = async (success, issue_type_name) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_issue_type", {
    params: { issue_type: issue_type_name },

    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_locations = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_locations", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_line_location = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_line_location", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_attribute_section = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_attribute_section", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_unit = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_unit", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_departments = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_departments", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_prioritys = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_priority_level", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_Ticket = async (ticketFormData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/create_ticket`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: ticketFormData,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const get_employee_working_status = async (
  success,
  search_item,
  page_number
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/employee_working_status", {
    params: { search_item: search_item, page_number: page_number },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_employee_work_status = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_employee_work_status", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_employee_working_status = async (statusFormData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/employee_working_status`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: statusFormData,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const update_ticket_status = async (
  ticket_id_to_be_updated,
  success
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/update_ticket_status`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      // Accept: "application/json",
      // "Content-Type": "application/json",
    },
    body: ticket_id_to_be_updated,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const update_workflow_engin = async (workflow_tobe_updated, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/workflow_engin`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: workflow_tobe_updated,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const cost_submit = async (cost_to_be_updated, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_ticket_finance_details`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: cost_to_be_updated,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_cost_details = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_ticket_finance_details", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_employees_for_assign = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_employee_assign", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_department_approval_data = async (
  success,
  search_item,
  page_number
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/department_approval_data", {
    params: { search_item: search_item, page_number: page_number },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_issue_type = async (issueType, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_issue_type`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: issueType,
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_roles = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_roles", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_role_group = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_role_group", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const getting_ticket_status = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/getting_ticket_status", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_role_by_group = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_role_by_group", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_roles_config = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_role_config", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_designations = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_designation", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_employee = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/add_employee_user", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_bulk_user = async (userFileData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/ImportUsertData`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: userFileData,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const add_bulk_location = async (locationFileData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/ImportLocationtData`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: locationFileData,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const get_employee_by_role = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_employee_by_role", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_instruction_status = async (success, ticket_id) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_instruction_status", {
    params: { ticket_id: ticket_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const reopen_ticket = async (reopen_formdata, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/ticket_reopen`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: reopen_formdata,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const return_ticket = async (return_formdata, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/return_ticket`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: return_formdata,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const ticket_workflow_creation = async (ticketFlow, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/ticket_workflow_creation`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ticket_flow: ticketFlow,
    }),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_ticket_workflow_creation = async (success, issue_type_id) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/ticket_workflow_creation", {
    params: { issue_type_id: issue_type_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const issue_type_dept_config = async (issueTypeDeptConfig, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_issue_depart`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(issueTypeDeptConfig),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_issue_type_dept = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/add_issue_depart", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const get_machine_list = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_machine_list", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const add_employee_user = async (user_data, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_employee_user`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user_data),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_employee_user_list = async (success, param_value) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/add_employee_user", {
    params: param_value,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const get_access_list_admin = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/add_access", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const get_disable_employee = async (success, param_value) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_disable_employee", {
    params: param_value,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const update_access_list_admin = async (
  access_list_to_be_updated,
  success
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_access`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data: access_list_to_be_updated }),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_employee_access_list = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_employee_access_list", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data.data);
  });
};

export const get_company_profiles = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_company_profiles", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data.data);
  });
};

export const add_department = async (department, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/department_config`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(department),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const add_role_group = async (role_group, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_role_group`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(role_group),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const delete_role = async (role, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/delete_role`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(role),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const delete_ticket = async (ticket_id, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/delete_ticket`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(ticket_id),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const delete_employee = async (employee, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/delete_employee_user`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(employee),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const delete_work_flow = async (employee, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/delete_ticket_workflow`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(employee),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const disable_work_flow = async (issue_type, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/disable_ticket_workflow`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(issue_type),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_location = async (location, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/location_config`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(location),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const update_location = async (location, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/update_location`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(location),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_role = async (role, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_role`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(role),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_designation = async (designation, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_designation`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(designation),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_employee_role_config = async (success, role_id) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/add_employee_role_config", {
    params: { role_id: role_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_employee_role_config1 = async (success, employee_id) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/add_employee_role_config", {
    params: { employee_id: employee_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_role_emp_config = async (role_emp_config, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_employee_role_config`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ data: [role_emp_config] }),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_location_group = async (group_name, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_group`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      group: group_name,
    }),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_location_groups = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_group", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const genarate_pdf_for_ticket = async (success, ticket_id) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/genarate_pdf_for_ticket`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ticket_id: ticket_id,
    }),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_user_profile = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_ticket_user_profile", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_vendor_details = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/vender_data", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const get_vendor_list = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_vendor_list", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const save_Vendor_details = async (formVendorData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/vender_data`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formVendorData,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_emp_sponsor_details = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_employee_sponsor", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const update_vendor_status = async (vendStatus, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/update_vendor_status`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(vendStatus),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const vendor_entry_gateForm = async (vendEntryGateReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/vendor_entry_gate`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(vendEntryGateReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const vendor_exit_gateForm = async (vendExitGateReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/vendor_exit_gate`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(vendExitGateReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_checklist_details = async (visitorId, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_vendor_checklist", {
    params: { vendor_id: visitorId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const get_machine_maintenance_pdf = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_machine_maintenance_pdf", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data, "maintenance");
  });
};

export const get_machine_breakdown_pdf = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_machine_breakdown_pdf", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data, "breakdown");
  });
};

export const machine_history_document = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/machine_history_document", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data, "history");
  });
};

export const get_all_checklist = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_all_checklist", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const get_spare_attribute = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_spare_attribute", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const add_vendor_checklist = async (vendor_checklist, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/add_vendor_checklist`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(vendor_checklist),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_notification = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_ticket_notification", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

// ---------------------------------------------------------------------------------

export const get_final_checklist_details = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_final_checklist_details", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_custom_checklist_details = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_custom_checklist_details", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const save_final_checklist_details = async (
  success,
  checklist_details
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/save_final_checklist_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(checklist_details),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const save_custome_checklist_details = async (
  success,
  checklist_details
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/save_custome_checklist_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(checklist_details),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const update_checklist_details = async (success, checklist_details) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/update_checklist_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(checklist_details),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const update_custom_checklist_details = async (
  success,
  checklist_details
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/update_custom_checklist_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(checklist_details),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const save_checklist_action_details = async (
  success,
  action_details
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/save_checklist_action_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(action_details),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const update_custom_checklist_values = async (
  success,
  checklist_details
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/update_custom_checklist_values`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(checklist_details),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const deleteMachine = async (machineid, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/delete_machine`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(machineid),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const add_company_profile = async (success, company_details) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/add_company_profile`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(company_details),
    body: company_details,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_spare_outward_logs = async (
  success,
  spare_outward_details
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/add_spare_outward_logs`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(spare_outward_details),
    //body: spare_outward_details,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const outward_spares_kit = async (success, spare_outward_details) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/outward_spares_kit`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(spare_outward_details),
    //body: spare_outward_details,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const chat_bot_respone_view = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/chat_bot_respone_view", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const add_bulk_spare_name = async (userFileData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/ImportSpareNameData`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: userFileData,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const order_spare_quantity = async (orderData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/order_spare_quantity`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(orderData),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const add_spares_kit = async (spare_kit, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/add_spares_kit`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(spare_kit),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const update_existing_spare_kit = async (spare_kit, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/update_existing_spare_kit`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(spare_kit),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};
export const delete_spare_details_data = async (spare_id, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/delete_spare_details_data`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(spare_id),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const spare_bulk_upload = async (userFileData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/spare_bulk_upload`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: userFileData,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const get_spare_by_machine = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_spare_by_machine", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const order_spare_detials = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/order_spare_detials", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_category_for_machine = async (param, success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_category_for_machine", {
    params: param,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_spares_kitlist = async (pagenumber, success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_spares_kitlist", {
    params: pagenumber,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_spare_Kit_Details = async (param, success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_spares_kit_details", {
    params: param,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_spare_names = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_spare_names", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_spare_kit_names = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_spare_kit_names", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_spares_type_list = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_spares_type_list", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const spare_dashboard = async (params, success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/pm/spare_dashboard", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_dashboard_spare_details = async (params, success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/pm/get_dashboard_spare_details", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_onclick_spare_details = async (params, success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/pm/get_onclick_spare_details", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
