import MainCard from "components/MainCard";
import React, { useEffect, useState } from "react";
import { Descriptions } from "antd";
import { get_api_function } from "utils/api";

function WorkHistory({ work_history }) {
  return (
    <Descriptions
      bordered
      column={2}
      layout="vertical"
      size="small"
      items={[
        {
          key: 1,
          label: "Start Date-Time",
          children: work_history?.work_start_date?.slice(0, 19),
        },
        {
          key: 2,
          label: "By",
          children: work_history?.work_start_employee,
        },
        work_history?.work_end_date !== "" &&
          work_history?.work_end_date !== "None" && {
            key: 3,
            label: "End Date-Time",
            children: work_history?.work_end_date?.slice(0, 19),
          },
        work_history?.work_end_date !== "" &&
          work_history?.work_end_date !== "None" && {
            key: 4,
            label: "By",
            children: work_history?.work_end_employee,
          },
      ]?.filter(Boolean)}
    />
  );
}

export default WorkHistory;
